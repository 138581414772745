import usePlatform from '@/composables/usePlatform'
import { RoutesMap } from '@/router'
import { useStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { useRouter, type RouteLocationNormalized } from 'vue-router'

export const useRouterStack = defineStore('routerStack', () => {
  const { weixin } = usePlatform()
  const routerStack = useStorage<any[]>('routerStack', [])
  const router = useRouter()

  // 添加路由到堆栈
  const push = (route: RouteLocationNormalized) => {
    routerStack.value.push({ name: route.name, params: route.params, query: route.query })
  }

  const popFromHistory = () => {
    return routerStack.value.pop()
  }

  const goBack = () => {
    if (!weixin) {
      try {
        router.back()
      } catch (error) {
        router.push('/')
      }
      return
    }
    const lastRoute = routerStack.value.length ? popFromHistory() : '/'
    const replaceUrl = lastRoute.name && lastRoute.name === RoutesMap.vip.order ? '/' : lastRoute
    router.replace(replaceUrl)
  }

  return {
    routerStack,
    push,
    goBack
  }
})
