import { useStorage } from '@vueuse/core'
import type { RouteLocationNormalized } from 'vue-router'

export const useUID = (route: RouteLocationNormalized) => {
  const $uid = useStorage('uid', '')
  const queryUid = route.query.uid as string

  if (queryUid) {
    $uid.value = queryUid
  }
}
