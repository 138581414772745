const InputTagName = ['INPUT', 'TEXTAREA']

function compositionStart(event: CompositionEvent & { target: { composing: boolean } }) {
  event.target.composing = true
}

function compositionEnd(event: CompositionEvent & { target: { composing: boolean } }) {
  event.target.composing = false
  // 发送一个事件出去，确保当输入法组合事件结束时，文本框内的内容能及时更新
  const e = new Event('input', { bubbles: true })
  event.target.dispatchEvent(e)
}

// 找到input元素：兼容当指令绑定到组件上时
function findInput(el: HTMLElement): HTMLElement | null {
  const queue: HTMLElement[] = []
  queue.push(el)
  while (queue.length > 0) {
    const cur = queue.shift()
    if (InputTagName.includes(cur.tagName)) {
      return cur
    }

    if (cur?.childNodes) {
      // @ts-ignore
      queue.push(...cur.childNodes)
    }
  }
  return null
}

function isFuncton(value: any) {
  return Object.prototype.toString.call(value) === '[object Function]'
}

function debounce(
  input: (event: Event & { target: { composing: boolean } }) => any,
  time: number
): (this: HTMLElement, event: Event) => any {
  // @ts-ignore
  let timer: string | number | NodeJS.Timeout | undefined
  return function (event: Event & { target: { composing: boolean } }) {
    if (event.target.composing) {
      return
    }

    if (timer) {
      clearTimeout(timer)
      timer = undefined
    }

    timer = setTimeout(() => {
      input(event)
      clearTimeout(timer)
      timer = undefined
    }, time)
  }
}

let functionDebounce: (this: HTMLElement, event: Event) => any

export default {
  mounted(el: HTMLElement & { _INPUT: HTMLElement | null }, binding: any) {
    const { value, arg } = binding
    if (value && isFuncton(value)) {
      let timeout = 600
      if (arg && !Number.isNaN(arg)) {
        timeout = Number(arg)
      }

      functionDebounce = debounce(value, timeout)
      const input = findInput(el)
      el._INPUT = input

      if (input) {
        input.addEventListener('input', functionDebounce)
        input.addEventListener('compositionstart', compositionStart)
        input.addEventListener('compositionend', compositionEnd)
      }
    }
  },
  beforeUnmount(el: HTMLElement & { _INPUT: HTMLElement | null }) {
    if (el._INPUT) {
      el._INPUT.removeEventListener('input', functionDebounce)
      el._INPUT.removeEventListener('compositionstart', compositionStart)
      el._INPUT.removeEventListener('compositionend', compositionEnd)
      el._INPUT = null
    }
  }
}
