import { postDouyinApplicationAPI } from '@/api/callback'
import useChannel from '@/composables/useChannel'
import { BAIDU_TOKEN } from '@/constant/common'
import { useStorage } from '@vueuse/core'
import dayjs from 'dayjs'

// 抖音回传
export const douYinCallbackAPI = (id, callback) => {
  callback()
  fetch('/api/v2/conversion', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json' // 告诉服务器我们正在发送JSON数据
    },
    body: JSON.stringify({
      event_type: 'active_register',
      context: {
        ad: {
          callback: id
        }
      },
      timestamp: dayjs().valueOf()
    })
  })
    .then((response) => response.json())
    .then((data) => console.log(data))
    .catch((error) => console.error('Error:', error))
}

// 百度api回调
export const baiduCallbackAPI = (bxId: string, callback) => {
  const { shareChannel } = useChannel()
  callback()
  fetch('/ocpcapi/api/uploadConvertData', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json' // 告诉服务器我们正在发送JSON数据
    },
    body: JSON.stringify({
      token: BAIDU_TOKEN[location.hostname],
      conversionTypes: [
        {
          logidUrl: `https://${location.hostname}?channel=${shareChannel.value}&bd_vid=${bxId}`,
          newType: 25
        }
      ]
    })
  })
    .then((response) => response.json())
    .then((data) => console.log(data))
    .catch((error) => console.error('Error:', error))
}

// 抖音应用回传
export const douyinApplicationCallbackAPI = async (event_type) => {
  const appDouyinUp = useStorage('appDouyinUp', false)
  // 判断是否在app环境
  if (window.BXJSBridge && !appDouyinUp.value) {
    try {
      const { data } = await window.BXJSBridge.getDeviceInfo()
      await postDouyinApplicationAPI(data.id, data.platform, event_type)
      appDouyinUp.value = true
    } catch (error) {
      console.error('Error:', error)
    }
  }
}
