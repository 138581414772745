import 'vant/es/dialog/style'

// ----- 确保样式优先级 -----
import '@/styles/main.scss'
import '@/styles/vant-theme.scss'
import 'prismjs/themes/prism.css'
import 'viewerjs/dist/viewer.css'
import 'wow.js/css/libs/animate.css'
//
import useSensors from '@/composables/useSensors'
// import useSentry from '@/composables/useSentry'
import inputDebouce from '@/directives/inputDirective'
import elementIcon from '@/utils/elementIcon'
import { globalComponents } from '@/utils/globalComponents'
import { copyPaste } from '@/utils/help'
import ElementPlus from 'element-plus'
import 'github-markdown-css/github-markdown-light.css'
import { createPinia } from 'pinia'
import VueViewer from 'v-viewer'
import { Lazyload } from 'vant'
import 'vant/es/image-preview/style'
import 'virtual:svg-icons-register'
import { createApp } from 'vue'
import asyncRegisterGlobalComponents from '../plugins/asyncRegisterGlobalComponents'
import App from './App.vue'
import i18n from './locales'
import router from './router'

const app = createApp(App)

app.use(ElementPlus)
app.use(createPinia())
app.use(router)
app.use(i18n)
app.use(VueViewer)
app.use(Lazyload)

app.use(asyncRegisterGlobalComponents, globalComponents)
app.use(elementIcon)

useSensors(app)

app.config.globalProperties.$copyText = (text: string, successMessage?: string) =>
  copyPaste(text, successMessage)

app.directive('inputDebouce', inputDebouce)
app.mount('#app')

// useSentry(app, router)

// 删除语音缓存，运行一段时候后删除
localStorage.removeItem('localAudioUrl')
