export enum UserRole {
  admin = '管理员',
  superman = '管理员',
  owner = '管理员',
  member = '使用者'
}

export enum EAllRole {
  admin = 'admin',
  superman = 'superman',
  owner = 'owner',
  member = 'member'
}
